import { tap } from "@/utils/tools";
import { axios } from ".";

interface ArticleDto {
  title: string;
  releaseTime: string;
  content: string;
  code: string;
}
export function fetchArticle(id: string) {
  return axios.post<unknown, ArticleDto>(`websiteNotice/getById?id=${id}`).then(
    tap(() => {
      // console.log(data);
    })
  );
}

interface ArticleDto {
  id: string;
  photo: string;
  title: string;
  content: string;
  releaseTime: string;
  code: string;
}

export async function fetchCategory(category: string) {
  // const result = await getList({ code: category, pagination: { page: 1, size: 10 } });
  // const id = result.data?.[0].id;
  // return fetchArticle(id!);
  return axios
    .post<unknown, Partial<ArticleDto>>(`websiteNotice/getOneWebsiteByCode?code=${category /** category */}`)
    .then(
      tap(() => {
        // console.log(data);
      })
    );
}

export async function fetchCategoryOrg(param: any) {
  // const result = await getList({ code: category, pagination: { page: 1, size: 10 } });
  // const id = result.data?.[0].id;
  // return fetchArticle(id!);
  return axios.post<unknown, Partial<ArticleDto>>(`websiteNotice/back/page`, param).then(
    tap(() => {
      // console.log(data);
    })
  );
}

export async function fetchOrgLoca() {
  return axios.get<unknown, Partial<ArticleDto>>(`businessOutlets`).then(
    tap(() => {
      // console.log(data);
    })
  );
}
