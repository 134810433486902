import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { message } from "ant-design-vue";
import { store } from "@/store/localstorage";

declare module "axios" {
  export interface AxiosRequestConfig {
    dataType?: ResponseHandleType;
    errorType?: ErrorHandleType;
  }
}

type ErrorCode = "jwt_illegal_token" & {
  /** keep it */
};

export enum ResponseHandleType {
  raw = 1,
  /** 返回列表 */
  list,
  /** 返回resultData */
  data,
}
export enum ErrorHandleType {
  /** 不捕获不提示 */
  default = 1,
  /** 不捕获但提示 */
  info,
  /** 捕获并提示 */
  catch,
  /** 捕获不提示 */
  silent,
}

interface Data {
  errorCode?: ErrorCode;
  errorMessage?: string;
  resultData?: {
    total?: number;
    records?: unknown[];
  };
  count?: number;
}

type Response = AxiosResponse<Data> & {
  config: AxiosRequestConfig;
};

enum RequestError {
  jwt_illegal_token = "jwt_illegal_token",
}

interface RequestConfig {
  logout: () => void;
  token: boolean;
}

function createRequest({ logout, baseURL, token }: RequestConfig & AxiosRequestConfig) {
  function lgout() {
    store.removeItem("token");
    logout();
  }

  const request = axios.create({
    baseURL,
    timeout: 60000,
  });

  const errorHandler = (
    error: Error & {
      response: AxiosResponse;
      config: AxiosRequestConfig;
    }
  ) => {
    const { response } = error;
    if (response?.status === 401) {
      lgout();
    } else if (response?.status === 500) {
      // lgout();
      // 未认证
    }
    return Promise.reject(error);
  };

  // request interceptor
  request.interceptors.request.use(async (config) => {
    const Authorization = await store.getItem<string>("token");
    const accountid = await store.getItem<string>("accountid");
    if (config.headers && Authorization && accountid && token) {
      config.headers["nttoken"] = Authorization; // "Bearer 7e2cc7c9-a4bb-4c4f-a032-394ae2577751"
      config.headers["accountid"] = accountid;
    }
    return config;
  }, errorHandler);

  // response interceptor
  request.interceptors.response.use((response: Response) => {
    if (response.config.responseType === "blob") {
      if (response.config.url?.includes("wx/pc/qrcode")) {
        if (response.headers["scenestr"]) {
          return {
            content: response.data,
            scenestr: response.headers["scenestr"],
          };
        }
      }
      return response.data;
    }
    const { errorCode } = response.data as Data;
    const dataType = response.config.dataType ?? ResponseHandleType.data;
    const errorType = response.config.errorType ?? ErrorHandleType.info;

    if (errorCode) {
      let errorMessage = response.data.errorMessage ?? response.data.errorCode ?? "";
      console.error(errorMessage);

      // token不合法，需要logout自动处理
      if (errorCode === RequestError.jwt_illegal_token) {
        errorMessage = errorMessage || "token过期";
        lgout();
        // default 策略模式下，错误会处理的，并且会提示，防止两次提示，不再提示错误, 其他情况提示
        if (errorType !== ErrorHandleType.default) {
          message.warning(errorMessage);
        }
        throw new Error(errorMessage);
      }

      // 其他情况错误处理
      if (errorType === ErrorHandleType.info) {
        message.error(errorMessage);
        throw new Error(errorMessage);
      }
      if (errorType === ErrorHandleType.catch) {
        message.error(errorMessage);
        if (dataType === ResponseHandleType.list) {
          return {
            total: 0,
            data: [],
          };
        }
        return;
      }
      if (errorType === ErrorHandleType.silent) {
        // do nothing
        if (dataType === ResponseHandleType.list) {
          return {
            total: 0,
            data: [],
          };
        }
        return;
      }
      // default and undefined
      throw new Error(errorMessage);
    }

    if (dataType === ResponseHandleType.list) {
      return {
        total: response.data.count || response.data.resultData?.total,
        data: response.data.resultData?.records ?? [],
      };
    }
    if (dataType === ResponseHandleType.data) {
      return response.data.resultData;
    }

    // defualt
    return response.data;
  }, errorHandler);

  return request;
}

export default createRequest;
