


















import { computed } from "vue-demi";
import { homeBanner1, homeBanner2, homeBanner5 } from "@/config";
import store from "@/store";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const themeCofig = computed(() => store.state.basic.themeCofig);
  const headBanners = [homeBanner1, homeBanner2, homeBanner5];
  return {
    headBanners
  };
};

export default __sfc_main;
