import companyIcon from "@/assets/images/yixingNewLogo.jpg";
import homeBanner1 from "@/assets/images/banner1-3.png";
import homeBanner2 from "@/assets/images/banner2.png";
import homeBanner5 from "@/assets/images/banner3.png";
import homeBanner3 from "@/assets/images/hotline.png";
import homeBanner4 from "@/assets/images/wxpublic.jpg";

export const APP_PREFIX = "nh";
export const phone = "88580131";
export const companyName = "福建省石狮供水股份有限公司";
export const address = "福建省石狮供水股份有限公司";
export const customerCenterPhone = "0595-88580131";
export const repairPhone = "0595-88553110";
export const zipCode = "362700";
export const copyRight = "闽ICP备11004420号";

export { companyIcon, homeBanner1, homeBanner2, homeBanner3, homeBanner4, homeBanner5 };
