import { getBindList } from "@/api/business";
import type { Module } from "vuex";
// import { store } from "../localstorage";
import { saveBindUserList, loadBindUserList } from "@/utils/cache";

/* let userList;
store.getItem('bindUserList').then((res) => {
  userList = res;
  console.log(userList, 'userList--999');
}); */

const state = {
  bindUserList: loadBindUserList(),
};

export type BindUser = typeof state;

export const bindUser: Module<typeof state, unknown> = {
  namespaced: true,
  state,
  mutations: {
    setBindUserList: (state, list) => {
      state.bindUserList = saveBindUserList(list);
    },
  },
  actions: {
    getUserList({ commit }) {
      return new Promise((resolve, reject) => {
        getBindList()
          .then((list) => {
            // console.log(list, "绑定用户列表");
            commit("setBindUserList", list);
            resolve(list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default bindUser;
