











































import { sendSms, getCaptcha, Login, fetchWxBindPhone, fetchAccountInfo } from "@/api/auth";
import router from "@/router";
import { reactive, ref } from "vue-demi";
import CoolDown from "./CoolDown.vue";
import { message } from "ant-design-vue";
import WXScan from "./WXScan.vue";
import store from "@/store"; // import { Modal } from "ant-design-vue";
// import { store as localstorage } from "@/store/localstorage";
// const hasLogin = ref(true);

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const cooldown = ref<InstanceType<typeof CoolDown> | null>(null);
  const state = reactive({
    step: "1",
    // '1' | '2'
    data: ({} as Partial<Login>),
    phone: (undefined as string | undefined),
    isPhoneValid: false,
    code: (undefined as string | undefined),
    smsCode: (undefined as string | undefined),
    codeImg: (null as null | string),
    submitLoading: false
  });

  async function onSuccess() {
    //   let res = (await fetchAccountInfo()) as any;
    //   console.log(res.verifiedFlag);
    //   console.log(store.state.auth.accountInfo);
    //   if (res.verifiedFlag !== "Y") {
    //     goHome();
    //   } else {
    //     Modal.confirm({
    //       title: "注意",
    //       content: "是否进入网厅实名认证",
    //       onOk() {
    //         //   Promise.all([localstorage.removeItem("token"), localstorage.removeItem("accountid")]).then(() => {
    //         //     store.commit("auth/setCleanInfo");
    //         //     hasLogin.value = false;
    //         //     goPage("/login");
    //         //   });
    //       },
    //       onCancel() {
    //         Promise.all([localstorage.removeItem("token"), localstorage.removeItem("accountid")]).then(() => {
    //           store.commit("auth/setCleanInfo");
    //           hasLogin.value = false;
    //           router.push("/login");
    //           //   state.sucess = false;
    //         });
    //       },
    //     });
    //   }
    goHome();
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any


  function onBind(data: any) {
    state.data = data;
    state.step = "2";
  }

  function isValidMobile(num: string) {
    return /1\d{10}/.test(num);
  }

  function onPhoneChange() {
    const phone = state.phone ?? "";

    if (isValidMobile(phone)) {
      state.isPhoneValid = true;
      setCaptcha(phone);
    } else {
      state.isPhoneValid = false;
    }
  }
  /** 获取验证码 */


  async function setCaptcha(phone: string) {
    const data = await getCaptcha(phone);
    state.codeImg = window.URL.createObjectURL(data);
  }

  function refreshCodeImg() {
    setCaptcha(state.phone ?? "");
  }
  /** 获取短信验证码 */


  function getSmsCode() {
    const phone = state.phone ?? "";

    if (isValidMobile(phone)) {
      cooldown.value?.cooldown();
      sendSms(phone);
    } else {
      message.warn("请输入正确的手机号码");
    }
  }
  /** 绑定手机号码 */


  function submit() {
    if (!isValidMobile(state.phone || "")) {
      message.warning("请输入正确的手机号码");
      return;
    }

    if (!state.code) {
      message.warning("图片验证码不能为空");
      return;
    }

    if (!state.smsCode) {
      message.warning("短信验证码不能为空");
      return;
    }

    if (!state.data.accountId) {
      message.warning("请先去扫码");
      return;
    }

    state.submitLoading = true;
    fetchWxBindPhone({
      accountId: state.data.accountId,
      code: state.code,
      phone: state.phone || "",
      smsCode: state.smsCode
    }).then(() => {
      state.submitLoading = false;
      goHome();
    }).finally(() => {
      state.submitLoading = false;
    });
  }

  function goHome() {
    store.dispatch("auth/getAccountInfo").then(() => {
      router.push("/");
    });
  }

  return {
    cooldown,
    state,
    onSuccess,
    onBind,
    onPhoneChange,
    refreshCodeImg,
    getSmsCode,
    submit,
    goHome
  };
};

__sfc_main.components = Object.assign({
  WXScan,
  CoolDown
}, __sfc_main.components);
export default __sfc_main;
