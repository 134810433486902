





















const __sfc_main = {};
__sfc_main.props = {
  day: {
    key: "day",
    required: true,
    type: [String, Number]
  },
  date: {
    key: "date",
    required: true,
    type: String
  },
  title: {
    key: "title",
    required: true,
    type: String
  },
  description: {
    key: "description",
    required: true,
    type: String
  },
  img: {
    key: "img",
    required: true,
    type: String
  },
  showImg: {
    key: "showImg",
    required: true,
    type: Boolean
  },
  codeName: {
    key: "codeName",
    required: true,
    type: null
  },
  dateYMDhms: {
    key: "dateYMDhms",
    required: true,
    type: null
  }
};
export default __sfc_main;
