




































import Container from "@/components/Container.vue";
import Nav from "@/components/Nav.vue";
import WX from "./WX.vue";
import { store } from "@/store/localstorage";
import { ref } from "vue-demi";
import SmsCode from "./SmsCode.vue";
import Register from "./Register.vue";
import Password from "./Password.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  store.removeItem("token");
  const loginType = ref("1");
  const isLogin = ref(true);

  function toRegister() {
    isLogin.value = false;
  }

  function toLogin() {
    isLogin.value = true;
  }

  function isMobileNavigator() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // match的返回值：如果匹配不到，返回null; 否则返回匹配到的 array

    return flag;
  }

  function onRegisterFinish(bind: boolean) {
    isLogin.value = true;

    if (bind) {
      loginType.value = "1";
    } else {
      loginType.value = "3";
    }
  }

  return {
    loginType,
    isLogin,
    toLogin,
    isMobileNavigator,
    onRegisterFinish
  };
};

__sfc_main.components = Object.assign({
  Container,
  Nav,
  WX,
  SmsCode,
  Password,
  Register
}, __sfc_main.components);
export default __sfc_main;
