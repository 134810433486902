/* eslint-disable */
import { Basic } from "@/types";
import { axios } from ".";

interface BaseDto {
  companyAddress: string;
  companyName: string;
  customerServiceCenter: string;
  emergencyRepairHotline: string;
  postalCode: string;
  recordNo: string;
  waterSupplyServiceHotline: string;
  linksMap: {
    [key: string]: string;
  };
}

export async function fetchBasicInfo(): Promise<Basic> {
  return axios.post<unknown, BaseDto>(`websiteNotice/queryWebsiteParams`);
}

export enum Gray {
  no = 0,
  yes = 1,
}
interface OneDto {
  grayFlag: Gray;
}
export async function getPublicMemorialCeremony() {
  return axios.post<any, any>(`system/getOneByTenantId`);
}
