

































































import { reactive } from "vue-demi";
import { Menu } from "@/types";
import { useRouter } from "@/utils/composition";
import { StyleValue } from "@vue/runtime-dom";
const __sfc_main = {};
__sfc_main.props = {
  index: {
    key: "index",
    required: false,
    type: Number,
    default: 0
  },
  menuVisible: {
    key: "menuVisible",
    required: false,
    type: Boolean,
    default: false
  },
  options: {
    key: "options",
    required: false,
    type: Array,
    default: () => []
  },
  itemStyles: {
    key: "itemStyles",
    required: false,
    type: null,
    default: undefined
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();

  function isShowDrop(item: any) {
    return ["/home", "/online/main"].indexOf(item.path) > -1;
  }

  function isHiddenNav(item: any) {
    return ["/netHall", "/introduce"].indexOf(item.path) > -1;
  }

  function isActiveNav(item: any) {
    let path = item.redirect || item.path;
    return location.href.indexOf(path.toLocaleLowerCase()) > -1;
  }

  function toPage(path: string) {
    router.push({
      path
    });
  }

  return {
    isShowDrop,
    isHiddenNav,
    isActiveNav,
    toPage
  };
};

export default __sfc_main;
