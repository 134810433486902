




















import { getWXQrCode, Login, LoginState, validateQrCode, validateBindQrCode, BindState, fetchAccountInfo } from "@/api/auth";
import { store } from "@/store/localstorage";
import { message } from "ant-design-vue";
import { onMounted, onUnmounted, reactive } from "vue-demi";
import { Modal } from "ant-design-vue";
import { store as localstorage } from "@/store/localstorage";
import stored from "@/store";
import router from "@/router";
const __sfc_main = {};
__sfc_main.props = {
  phone: {
    key: "phone",
    required: false,
    type: [String, null],
    default: undefined
  },
  register: {
    key: "register",
    required: false,
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const INTERVAL = 3000;
  let alive = true; // 防止定时器在组件destroy时重新启动

  const props = __props;
  const emit = __ctx.emit;
  let timer: number;
  const state = reactive({
    qrCode: "",
    scenestr: "",
    sucess: false,
    data: ({} as Partial<Login>),
    qrCodeLoading: false,
    isOutdate: false
  });
  /**
   * 轮询，判断是否绑定手机，进入第二步
   */

  async function loginFlow() {
    await getQrCode();
    await validate();
  }
  /**
   * 获取Qrcode
   */


  function getQrCode() {
    state.qrCodeLoading = true;
    return getWXQrCode(props.phone).then(data => {
      console.log("data", data);
      state.qrCode = window.URL.createObjectURL(data.content);

      if (state.qrCode.length) {
        state.qrCodeLoading = false;
      }

      state.scenestr = data.scenestr;
    }).catch(() => {
      state.qrCodeLoading = false;
    });
  }

  function reset() {
    clearTimeout(timer);

    if (alive) {
      timer = setTimeout(() => {
        loginFlow();
      }, INTERVAL);
    }
  }

  async function validate() {
    if (state.scenestr) {
      if (!props.phone) {
        const res = await validateQrCode(state.scenestr); // Qrcode失效

        if (!res?.loginState) {
          state.isOutdate = true;
          return;
        }

        if (res?.loginState === LoginState.success) {
          state.data = res;
          store.setItem("token", res.token);
          store.setItem("accountid", res?.accountId);
          store.setItem("openid", res?.openid);
          state.sucess = true;
          let respon = (await fetchAccountInfo() as any);
          console.log(respon.verifiedFlag);

          if (respon.verifiedFlag === "Y") {
            emit("success", res);
            return;
          } else {
            Modal.confirm({
              title: "注意",
              content: "是否进入网厅实名认证",

              onOk() {
                stored.dispatch("auth/getAccountInfo").then(() => {
                  router.push("/online/certification");
                  return;
                });
              },

              onCancel() {
                Promise.all([localstorage.removeItem("token"), localstorage.removeItem("accountid")]).then(() => {
                  stored.commit("auth/setCleanInfo");
                  state.sucess = false;
                  reset();
                  return;
                });
              }

            });
          } // emit("success", res);
          // return;

        } //   if (res?.loginState === LoginState.needPhone) {
        //     state.data = res;
        //     store.setItem("token", res.token);
        //     store.setItem("accountid", res?.accountId);
        //     store.setItem("openid", res?.openid);
        //     // 没有短信注册登录
        //     // emit("needBind", res);
        //     emit("success", res);
        //     state.sucess = true;
        //     return;
        //   }


        if (res?.loginState === LoginState.fail) {
          message.warning("登录失败，请稍后再登录");
          reset();
          return;
        }

        if (res?.loginState === LoginState.loading) {
          clearTimeout(timer);

          if (alive) {
            timer = setTimeout(() => {
              validate();
            }, INTERVAL);
          }
        }

        return;
      } else {
        const res = await validateBindQrCode(state.scenestr, props.phone);

        if (!res?.state) {
          state.isOutdate = true;
          return;
        }

        if (res?.state === BindState.success) {
          emit("success");
          return;
        }

        if (res?.state === BindState.loading) {
          clearTimeout(timer);

          if (alive) {
            timer = setTimeout(() => {
              validate();
            }, INTERVAL);
          }

          return;
        }

        if (res?.state === BindState.fail) {
          reset();
          return;
        }

        return;
      }
    }
  }

  function onOutdate() {
    if (state.isOutdate) {
      state.isOutdate = false;
      reset();
    }
  }

  onMounted(() => {
    loginFlow();
  });
  onUnmounted(() => {
    alive = false;
    clearTimeout(timer);
  });
  return {
    props,
    state,
    onOutdate
  };
};

export default __sfc_main;
