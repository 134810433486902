














import { companyIcon, homeBanner3, homeBanner4 } from "@/config";
import store from "@/store";
import { computed, ref } from "vue-demi";
import Tab from "./Tab/index.vue";
import { useGoPage, useRoute, useRouter } from "@/utils/composition";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const pages = computed(() => store.state.auth.menu.filter(item => isMobileTab(item)));
  console.log(pages);
  const goPage = useGoPage();
  const index = computed(() => {
    return pages.value.findIndex(page => {
      return route.value.matched.find(match => match.name === page.name && page.showFlag);
    });
  });
  const menuVisible = ref(false);

  function toggleMenu() {
    menuVisible.value = true;
  }

  function onClose() {
    menuVisible.value = false;
  }

  function isMobileTab(item: any) {
    if (item.id === "online") {
      return item.showFlag && !isMobileNavigator();
    } else {
      return item.showFlag;
    }
  }

  function isMobileNavigator() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // match的返回值：如果匹配不到，返回null; 否则返回匹配到的 array

    return flag;
  }

  return {
    companyIcon,
    homeBanner3,
    homeBanner4,
    pages,
    goPage,
    index,
    menuVisible,
    toggleMenu,
    onClose
  };
};

__sfc_main.components = Object.assign({
  Tab
}, __sfc_main.components);
export default __sfc_main;
