/* eslint-disable */
import { Basic } from "@/types";
import { axiosAccount, axios, axiosElect, axiosNoToken } from ".";
import { PartialResponse } from "@/utils/type";
import { store } from "@/store/localstorage";
interface BaseDto {
  companyAddress: string;
  companyName: string;
  customerServiceCenter: string;
  emergencyRepairHotline: string;
  postalCode: string;
  recordNo: string;
  waterSupplyServiceHotline: string;
  linksMap: {
    [key: string]: string;
  };
}

export async function fetchBasicInfo(): Promise<Basic> {
  return axios.post<unknown, BaseDto>(`websiteNotice/queryWebsiteParams`);
}

interface WaterUser {
  id: string;
  userName: string;
  tags: string[];
  userNumber: string;
  address: string;
  start: number;
  end: number;
  quantity: number;
  price: number;
}

/**
 * 查询用水户
 * @param query
 * @returns
 */
export async function selectWaterUserList(param: any) {
  // return [
  //   {
  //     id: "1",
  //     clientName: "用户1",
  //     clientAddress: "湖北省武汉市关山大道泛悦城17",
  //     clientCode: "123456788",
  //     idNum: "310222200000001876",
  //   },
  //   {
  //     id: "2",
  //     clientName: "用户2",
  //     clientAddress: "湖北省武汉市关山大道泛悦城17",
  //     clientCode: "123456788",
  //     idNum: "310222200000001876",
  //   },
  //   {
  //     id: "3",
  //     clientName: "用户3",
  //     clientAddress: "湖北省武汉市关山大道泛悦城17",
  //     clientCode: "123456788",
  //     idNum: "310222200000001876",
  //   },
  // ];
  return axios.post<unknown, PartialResponse<WaterUser[]>>(`/revenue/selectWaterUserList`, param);
}

interface bindClientQuery {
  accountId: string;
  clientCodes: unknown[];
  bindGroup: string;
}
/**
 * 绑定用水户
 * @param query
 * @returns
 */
export async function bindClient(query: bindClientQuery) {
  return axios.post<unknown, BaseDto>(`/waterUser/bindClient`, query);
}

/**
 * 解绑用户
 * @param query
 * @returns
 */
export async function unbindClient(id: string) {
  const accountId = await store.getItem("accountid");
  return axios.post<unknown, any>(`/waterUser/unBindClient`, {
    accountId,
    clientCode: id,
  });
}

export interface BindItem {
  /** 户号 */
  clientCode: string;
  /** 户名 */
  clientName: string;
  /** 地址 */
  clientAddress: string;
  /** 手机号码 */
  phoneNum: string;
  /** 表册号 */
  mbCode: string;
  /** 表册名 */
  mbName: string;
  /** 抄表员 */
  mbStaff: string;
  /** 联系电话 */
  tel: string;
  /** 证件号吗 */
  idNum: string;
  /** 合同编号 */
  contractNo: string;
  /** 本期起止码 */
  mrNow: string;
  /** 止码止码 */
  stopCode: string;
  /** 本期水量 */
  nowCount: string;
  /** 绑定分组 */
  bindGroup: string;
  /** 欠费 */
  arrears: number;
  /** 预存余额 */
  realBalance: number;
  /** 违约金 */
  late: number;
  /** 起码 */
  startCode: string;
}

/**
 * 查询绑定用户缴费列表
 * @param accountIdQuery
 * @returns
 */
export async function getBindList() {
  const id = await store.getItem("accountid");
  // return [
  //   {
  //     id: "1",
  //     userName: "上海威派格股份有限公司武汉分公司",
  //     tags: ["公司"],
  //     userNumber: "21211116001",
  //     address: "湖北省武汉市关山大道泛悦城17",
  //     start: 532,
  //     end: 577,
  //     value: 45,
  //     price: 102.05,
  //   },

  //   {
  //     id: "2",
  //     userName: "上海威派格股份有限公司武汉分公司",
  //     tags: ["公司"],
  //     userNumber: "21211116001",
  //     address: "湖北省武汉市关山大道泛悦城17",
  //     start: 532,
  //     end: 577,
  //     value: 45,
  //     price: 202.05,
  //   },

  //   {
  //     id: "3",
  //     userName: "上海威派格股份有限公司武汉分公司",
  //     tags: ["公司"],
  //     userNumber: "21211116001",
  //     address: "湖北省武汉市关山大道泛悦城17",
  //     start: 532,
  //     end: 577,
  //     value: 45,
  //     price: 302.05,
  //   },
  // ];
  return axios.get<unknown, BindItem[]>(`/waterUser/getBindList/${id}`).then((list) => {
    return list?.map((item) => {
      const startEnd = item.mrNow ?? "";
      const startCode = startEnd.replace(/(.*)(-.*)/, (_, start: string) => start);
      return {
        ...item,
        startCode,
      };
    });
  });
}

/**
 * 查询用户信息
 * @param data
 * @returns
 */
export async function selectWaterUserDetails(data: Record<string, any>) {
  return axios.post<unknown, BaseDto>(`/revenue/selectWaterUserDetails`, data);
}

/**
 * 查询开票信息
 * @param yhid
 * @returns
 */
export async function getInvoiceList(yhid: string) {
  return axios.get<unknown, any>(`/invoice/listCb?yhid=${yhid}`);
}

/**
 * 申请开票
 * @param data
 * @returns
 */
export async function applyInvoice(data: Record<string, any>) {
  return axios.post<unknown, BaseDto>(`/invoice/applyInvoice`, data);
}

/**
 * 查询开票信息接口
 * @param yhid
 * @returns
 */
export function getBillDetails(yhid: string) {
  return axios.get<unknown, any>(`/revenue/yhxx?yhid=${yhid}`);
}

/**
 * 修改开票信息
 * @param data
 * @returns
 */
export async function editBillInfo(data: Record<string, any>) {
  return axios.post<unknown, BaseDto>(`/revenue/editKpxx`, data);
}

/**
 * 申请发票
 * @param sflsh
 * @returns
 */

export async function applyOpenBill(sflsh: string) {
  return axios.post<unknown, BaseDto>(`/invoice/apply?sflsh=${sflsh}`);
}

/**
 * 查询用户信息
 * @param openIdQuery
 * @returns
 */
export async function queryUser(openIdQuery: string) {
  return axios.get<unknown, any>(`/account/queryUser?openid=${openIdQuery}`);
}

/**
 * 查询字典
 * @param sysDictCode
 * @returns
 */
export async function sysDictListByCode(sysDictCode: string) {
  return axiosAccount.get<unknown, BaseDto>(`/sysDictListByCode/${sysDictCode}`);
}

/**
 * 进度查询
 * @param query
 * @returns
 */
export async function selectBizByState(query: Record<string, any>) {
  return axios.post<unknown, BaseDto>(`/bizHandle/selectBizByState`, query);
}

export async function getAreaInfoByBranchId() {
  return axios.get<unknown, any>(`/revenue/getAreaInfoByBranchId`);
}

export async function getOrgInfoByAddress(address: string) {
  return axios.get<unknown, any>(`/revenue/getOrgInfoByAddress?address=${address}`);
}

/**
 * 通过id查表单详情
 * @param id
 * @returns
 */
export async function selectBizById(id: string) {
  return axios.post<unknown, BaseDto>(`/bizHandle/selectBizById?bizHandleId=${id}`);
}
//新增或更新业务信息
export function saveBizHandle(query: Record<string, any>) {
  return axios.post<any, any>(`/bizHandle/saveBizHandle`, query);
}

// 提交业务表单-form
export function saveBizHandleForm(query: Record<string, any>) {
  return axios.post<any, any>(`/bizHandle/saveBizHandleForm`, query);
}

// 取消业务
export async function cancelBiz2(id: string) {
  return axios.post<unknown, BaseDto>(`/bz/cancel?bizHandleId=${id}`);
}

//微信端用水类型
export function enterpriseType() {
  return axios.get<any, any>(`/enterpriseType`);
}

/**
 * 查询户主信息
 * @param openIdQuery
 * @returns
 */
export async function fetchUserInfo(data: any) {
  return axios.post<unknown, any>(`/revenue/selectMrReadList`, data);
}

interface Payment {
  cancelAmount: number;
  clientCode: string;
  nowBalance: number;
  payAmount: number;
  payMethod: string;
  payTime: string;
}

export function fetchPaymentList(id: string) {
  return axios.post<unknown, Payment[]>(`/revenue/selectClientPayList`, {
    clientCodes: [id],
  });
}

// 客户端获取须知
export function getInstruction(query: Record<string, any>) {
  return axios.post<any, any>(`/instruction/list`, query);
}

//查询用户状态是否已停用
export function getIsStopUser(id: string) {
  return axios.get<any, any>(`/revenue/yhxx?yhid=${id}`);
}

export function getYsOrgCode(id: string) {
  return axios.get<any, any>(`/revenue/getYsOrgCode?yhid=${id}`);
}

// 设置---校验手机 验证码 是否正确
export function checkCode(phone: string, verifyCode: string) {
  return axios.get<any, any>(`/bizHandle/checkCode/${phone}/${verifyCode}`);
}

// 设置---获取手机验证码
export function getCode(phone: string) {
  return axios.get<any, any>(`/bizHandle/verifyCode/${phone}`);
}

// 客户档案上传图片
export function newFileUpload(query: Record<string, any>) {
  return axios.post<any, any>(`/fileOpt/newFileUpload`, query);
}

// export function personVerify(query: Record<string, any>) {
//   return axios.post<any, any>(`/esignInfo/personVerify`, query);
// }

export function personVerify(query: Record<string, any>) {
  return axiosElect.post<any, any>(`/elect/psnAuth`, query);
}

// export function getOrgVerifyUrl(query: Record<string, any>) {
//   return axios.post<any, any>(`/esignInfo/getOrgVerifyUrl`, query);
// }

export function getOrgVerifyUrl(query: Record<string, any>) {
  return axiosElect.post<any, any>(`/elect/orgAuth`, query);
}

// export function getCardTypes(type: string) {
//   return axios.get<any, any>(`/esignInfo/getEsignCardTypes/${type}`);
// }

export function getCardTypes(type: string) {
  return axiosElect.get<any, any>(`/elect/getEsignCardTypes/${type}`);
}

export async function selectDictEnumByCode() {
  return axios.get<any, any>(`/queryHandleStateDict`);
}

export function userCancelHandel(query: Record<string, any>) {
  return axios.post<any, any>(`/bizHandle/userCancelHandel`, query);
}
// 解除企业认证
export function delOrgVerify(query: Record<string, any>) {
  return axiosElect.post<any, any>(`/elect/delOrgVerify`, query);
}

// 查询户号关联的人口
export function getPeopleInfo(yhid: string) {
  return axios.get<any, any>(`/revenue/getPeopleInfo?yhid=${yhid}`);
}
