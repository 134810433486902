import { getList, getSearchList } from "@/api/list";
import { Pagination } from "@/types";
import { useAsyncState } from "@vueuse/core";
import { ComponentInstance, getCurrentInstance, reactive, ref, toRefs, UnwrapRef, watch } from "vue-demi";

export function useThis<T>() {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error("this was not found");
  }
  return instance.proxy as ComponentInstance & T;
}

export function useRoute() {
  const _this = useThis();

  const route = ref(_this.$route);

  watch(
    () => _this.$route,
    () => {
      route.value = _this.$route;
    }
  );
  return route;
}

export function useRouter() {
  const _this = useThis();
  return _this.$router;
}

export function useGoPage() {
  const router = useRouter();

  return function goPage(page: string) {
    router.push(page);
  };
}

export function useFetchList(
  code: string,
  pagi: Partial<Pagination> = {},
  showAll = false, // 初始参数，为防止冲突，图片新闻、企业新闻所需参数另起
  needFilter = false, // 是否需要过滤，图片新闻、企业新闻传 true
  filterPhoto = false, // 是否需要过滤图片，图片新闻 true、企业新闻传 false
  photoNews = "" // 接口过滤图片，图片新闻 photo、企业新闻传空
) {
  const pagination = reactive({
    page: pagi.page || 1,
    size: pagi.size || 9,
  });

  console.log(code, needFilter, filterPhoto, "useFetchList--code");

  code = code === "corporate_news_img" ? "corporate_news" : code;

  const { execute, ...rest } = useAsyncState(
    () =>
      getList({ code, pagination: pagination, showAll, photoNews }).then((res) => {
        const packData = res.data.map((item: any) => {
          return {
            isAsc: true,
            field: "releaseTime",
            id: item.id,
            photo: item.photo,
            img: item.img,
            day: item.day,
            monthDay: item.monthDay,
            year: item.year,
            date: item.date,
            dateYMD: item.dateYMD,
            dateYMDhms: item.dateYMDhms,
            title: item.title ?? "",
            type: item.contentType,
            content: item.content,
            description: item.description ?? "",
            code: item.code,
            codeName: item.codeName,
            photoNews: item.photoNews,
            fileUploads: item?.fileUploads ?? [],
          };
        });

        const imgData = packData.filter((val: any) => val.photo && val.photoNews !== null); // 图片新闻
        const textData = packData.filter((val: any) => !val.photoNews); // 企业要闻

        if (code === "corporate_news") {
          const lastData = !needFilter ? packData : filterPhoto ? imgData : textData;
          const lastDataName = !needFilter ? "packData" : filterPhoto ? "imgData" : "textData";
          console.log(lastData, lastDataName, "企业新闻");
        }

        return {
          data: !needFilter ? packData : filterPhoto ? imgData : textData,
          total: res.total,
        };
      }),
    {
      total: 0,
      data: [],
    }
  );

  function onChange() {
    execute();
  }
  return {
    ...rest,
    ...toRefs(pagination),
    onChange,
  };
}

export function useSearchFetchList(content: () => string, pagi: Partial<Pagination> = {}) {
  const pagination = reactive({
    page: pagi.page || 1,
    size: pagi.size || 9,
  });

  const { execute, ...rest } = useAsyncState(
    () =>
      getSearchList({ content: content(), pagination: pagination }).then((res) => {
        const packData = res.data.map((item: any) => {
          return {
            id: item.id,
            path: item.path ?? "",
            title: item.title ?? "",
            codeName: item.codeName ?? "",
            img: item.img,
            description: item.description ?? "",
            content: item.content,
            photoNews: item.photoNews,
            photo: item.photo,
            fileUploads: item?.fileUploads ?? [],
            type: item.contentType,
            day: item.day,
            monthDay: item.monthDay,
            year: item.year,
          };
        });

        return {
          data: packData,
          total: res.total,
        };
      }),
    {
      total: 0,
      data: [],
    }
  );

  function onChange() {
    execute();
  }
  return {
    ...rest,
    ...toRefs(pagination),
    onChange,
  };
}

export function useFetch<Args extends unknown[], Result>(fn: (...args: Args) => Promise<Result | undefined>) {
  const state = reactive<{
    loading: boolean;
    data: Result | undefined;
    errorMessage: string;
  }>({
    loading: false,
    data: undefined,
    errorMessage: "",
  });

  async function innerFetch(...args: Args) {
    state.loading = true;

    // 不太需要，考虑移除
    if (typeof fn !== "function") {
      throw new Error("参数必须为函数");
    }
    try {
      const res = await fn(...args);
      state.loading = false;
      state.data = res as UnwrapRef<Result | undefined>;
      state.errorMessage = "";
    } catch (error) {
      state.loading = false;
      state.data = undefined;
      if (error instanceof Error) {
        state.errorMessage = error.message || "请求错误";
      } else {
        state.errorMessage = "请求错误";
      }
    }
  }

  return reactive({
    fetch: innerFetch,
    ...toRefs(state),
  });
}

export type UseFetch = ReturnType<typeof useFetch>;

// interface Pagination {
//   page: number;
//   size: number;
// }
// export function useFetchList<Args extends unknown[], Item>(
//   fn: (
//     pagination: Pagination,
//     ...args: Args
//   ) => Promise<{ total: number; data: Item[] } | undefined>
// ) {
//   const state = reactive({
//     page: 1,
//     size: 20,
//     loading: false,
//     data: [] as Item[],
//     total: 0,
//     errorMessage: "",
//   });

//   state.data;

//   async function innerFetch(
//     { page = state.page, size = state.size }: Partial<Pagination> = {
//       page: state.page,
//       size: state.size,
//     },
//     ...args: Args
//   ) {
//     state.page = page;
//     state.size = size;
//     state.loading = true;
//     if (typeof fn !== "function") {
//       throw new Error("参数必须为函数");
//     }
//     try {
//       /* res 必须固定格式 data, total */
//       /* page, size需不要同步？！antd pagination会自动同步到最后一页 */
//       const res = await fn(
//         {
//           page: state.page,
//           size: state.size,
//         },
//         ...args
//       );
//       state.loading = false;

//       state.data = res?.data as UnwrapRefSimple<Item[]>;
//       state.total = res?.total ?? 0;
//       state.errorMessage = "";
//     } catch (error) {
//       state.loading = false;
//       state.data = []; // ?! 重置为[]， total不变
//       if (error instanceof Error) {
//         state.errorMessage = error.message || "请求错误";
//       } else {
//         state.errorMessage = "请求错误";
//       }
//     }
//   }

//   return reactive({
//     fetch: innerFetch,
//     ...toRefs(state),
//   });
// }
