import { getMenu } from "@/api/auth";
import router from "@/router";
import NotFound from "@/views/404.vue";
import { RouteConfig } from "vue-router";
import Article from "@/views/Article/index.vue";
import SiteSearch from "@/views/SiteSearch/index.vue";
import store from "@/store";
import { DeepPartial } from "../utils/type";
import { Menu, MenuType } from "@/types";

function loadModule(name: string) {
  return () =>
    import(
      /* webpackInclude: /\.(vue|(j|t)sx?)$/ */
      `@/views${name}`
    ).then((module) => {
      return module;
    });
}

function transform(route?: DeepPartial<Menu>, redirect = true): RouteConfig | undefined {
  if (route?.meta?.type !== MenuType.frame && route?.showFlag) {
    return {
      path: route?.path ?? "",
      name: route?.name ?? "",
      redirect: redirect ? route?.redirect : undefined,
      component: loadModule(`/${route?.component}`.replaceAll(/\/\//g, "/")),
      children: route?.children
        ? (route?.children.map((route) => transform(route)).filter((item) => !!item) as RouteConfig[])
        : undefined,

      meta: {
        id: route.id,
        component: route?.component,
        ...route.meta,
      },
    };
  }
  return;
}

interface Meta {
  id: string;
  type: MenuType;
  name: string;
  eName: string;
  needLogin: boolean;
}

function findRoute(id: string, route: RouteConfig): RouteConfig | void {
  if ((route.meta as Meta)?.id === id) {
    return route;
  }
  if (route.children) {
    return findRouteInArray(id, route.children);
  }
}

function findRouteInArray(id: string, routes: RouteConfig[]) {
  return routes.reduce<RouteConfig | void>((result, route) => {
    if (result) {
      return result;
    }
    return findRoute(id, route);
  }, undefined);
}

export function initMenu() {
  return getMenu().then((menu) => {
    store.commit("auth/setMenu", menu);

    const routes = [
      ...((menu?.map?.((route: any) => transform(route))?.filter((item: any) => !!item) as RouteConfig[]) ?? []),
      {
        path: "/article/:id",
        name: "article",
        component: Article,
      },
      {
        path: "*",
        name: "notFound",
        component: NotFound,
      },
      {
        path: "/siteSearch/:keyword",
        name: "siteSearch",
        component: SiteSearch,
      },
    ];

    console.log(routes, "initMenu--routes");
    function addChildren(code: string) {
      const result = findRouteInArray(code, routes);
      if (result) {
        const component = `/${result.meta?.component}/List`.replaceAll(/\/\//g, "/");
        result.children = [
          {
            path: `${result.path}/:id`,
            component: () => import(`@/views/Page/Detail.vue`),
          },
          {
            path: "",
            component: () =>
              import(
                /* webpackInclude: /\.(vue|(j|t)sx?)$/ */
                `@/views${component}`
              ),
            // component: () => import(`@/views/Group/Event/List`),
            meta: {
              parentId: result.meta?.id, // 仅供列表获取数据
            },
          },
        ];

        result.redirect = `${result.path}/list`;
      }
    }

    [
      "corporate_news_img",
      "corporate_news",
      "industry_news",
      "special_report",
      "media_focus",
      "staff_style",
      "massesParty",
      "employee_education",
      "corporate_culture",
      "ezhou_water_ affairs_news",
      "planned_stop_water",
      "sudden_stop_water",
      "water_quality_bulletin",
      "water_pressure_information",
      "bidding_announcement",
      "water_guidelines",
      "information_hire",
      "service_announcement",
      "laws_and_regulations",
      "national_norms",
      "other_services",
      "common_sense_of_water_use",
      "service_commitment",
      "water_charge_standard",
      "enterprise_party_building",
      "trade_union_work",
      "party_conduct_and_clean_government",
      "youth_garden",
      "learning_materials",
    ].forEach(addChildren);

    routes.forEach((route) => {
      router.addRoute(route);
    });
  });
}
